* {
  font-family: "Inter", sans-serif;
}

body {
  background-image: url("./img/background2.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
a:hover {
  text-decoration: none;
}

.btn-primary {
  background-color: #0d1f49;
  border: none;
}
.btn-primary:hover {
  opacity: 0.7;
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.center-spinner-box {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 36px;
  height: 36px;
  margin: 8px;
  border: 4px solid gray;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: gray transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mainTitle {
  font-weight: 900;
  font-size: 50px;
  margin-top: 40px;
}
.mainLogo {
  width: 480px;
  max-width: 93.14159vw;
}
.mainLogo:hover {
  opacity: 0.8;
  cursor: pointer;
}

h1 {
  font-size: 24px;
  font-weight: 900;
  color: #0d1f49;
}

/* HOME PAGE */
.label-row {
  background-color: lightgray;
  background-color: #0d1f49;
  color: white;
  padding: 6px 12px;
  text-align: center;
  font-size: 15px;
}

.pharmacy-row-container {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 15px 0px rgba(155, 155, 155, 0.363);
}
.pharmacy-row {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 16px;
}
.pharmacy-row:hover {
  position: relative;
  bottom: 1px;
  opacity: 0.7;
}
.ho .pharmacy-row.top {
  border-top: none;
}
.pharmacy-row-contents {
}
.home-logo-box {
  width: 120px;
  width: 30%;
  height: 60px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-logo {
  max-width: 100%;
  max-height: 100%;
  background-color: white;
}
.map-image {
  max-width: 80%;
  margin: 0 auto;
}

.home-info-box {
  width: 70%;
  text-align: center;
}
.home-info-box h2 {
  color: black;
  font-size: 18px;
  font-weight: 900;
}
.home-info-box p {
  color: rgb(64, 64, 64);
  font-size: 15px;
  padding: 4px 0;
}

.notify-form-frame {
  height: 380px;
  width: 100%;
  border-radius: 20px;
}

.disclaimer-text {
  font-size: 14px;
}

/* Individual Pharmacy Page */

@media only screen and (min-width: 992px) {
  .pharmacy-logo-box-column {
    background-color: white !important;
  }
  .pharmacy-logo-box {
    border: solid 1px;
    box-shadow: 0 56px 70px hsl(240deg 5% 53% / 6%),
      0 1px 12px hsl(240deg 5% 53% / 21%), 0 56px 70px hsl(0deg 0% 94% / 60%);
  }
}

.pharmacy-logo-box {
  background-color: white;
  padding: 8px;
  min-height: 100%;
  max-width: 100%;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  border-radius: 6px;
}
.pharmacy-logo-box img {
  max-width: 100%;
  background-color: white;
}

.address-box p {
  margin-bottom: 0;
  font-size: 15px;
}

.contact-box {
  padding: 12px 0 0px 0;
  display: flex;
  /* justify-content: center; */
  font-size: 14px;
  gap: 16px;
  max-width: 400px;
}

.pharmacy-website {
  margin-bottom: 0;
  font-size: 14px;
  color: #0d1f49;
  text-decoration: none !important;
}
.pharmacy-website:hover {
  opacity: 0.7;
}

.pharmacy-phone {
  margin-bottom: 0;
  font-size: 14px;
  color: #0d1f49;
}
.pharmacy-phone:hover {
  opacity: 0.7;
}
.pharmacy-social {
  margin-bottom: 0;
  margin-top: -4px;
  font-size: 18px;
  color: #0d1f49;
}
.pharmacy-social:hover {
  opacity: 0.7;
}

.priceSearchInputContainer {
  max-width: 94vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border: solid 1px #0d1f493b;
  background: white;
  border-radius: 28px;
  padding: 0px 12px;
  margin-bottom: 16px;
  overflow: hidden;
  /* box-shadow: inset 0 1px 2px 0 rgb(129, 129, 141); */
}
.priceSearchInput {
  border: none;
  padding: 8px 8px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
}
.searchX:hover {
  cursor: pointer;
  opacity: 0.7;
}

.unitsToggleContainer {
  margin: 0 auto 16px auto;
  background-color: #0d1f493b;
  padding: 2px;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 15px;
  gap: 2px;
  border-radius: 6px;
  overflow: hidden;
  max-width: 500px;
  box-shadow: 0 56px 70px hsl(240deg 5% 53% / 6%),
    0 1px 12px hsl(240deg 5% 53% / 21%), 0 56px 70px hsl(0deg 0% 94% / 60%);
}
.unitsToggle {
  cursor: pointer;
  text-align: center;
  padding: 8px;
  background-color: rgb(233, 233, 233);
  width: 33%;

  input {
    width: 100%;
  }
}
.unitsToggle:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.unitsToggle:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.unitsToggle:hover {
  opacity: 0.8;
}
.active {
  background-color: #0d1f49;
  color: white;
}

.customUnitsInput::placeholder {
  color: #0d1f49;
  font-weight: 500;
  font-size: 13px;
}

.pharmacyDisclaimer {
  font-size: 12px;
  padding-bottom: 24px;
}

.priceRow {
  background-color: white;
  box-shadow: 0 56px 70px hsl(240deg 5% 53% / 6%),
    0 1px 12px hsl(240deg 5% 53% / 21%), 0 56px 70px hsl(0deg 0% 94% / 60%);
  margin-bottom: 4px;
  border-radius: 10px;
}
.drug-price-box {
  text-align: right;
}
.drugName {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 4px;
  color: #0d1f49;
}
.ndc {
  font-size: 14px;
}
.member-price-text {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  color: #0d1f49;
}
.non-member-price-text {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  color: rgb(92, 92, 92);
}

.unit-text {
  margin-bottom: 10px;
  font-size: 13.5px;
}

.order-row {
  display: none;
}
.order-row.open {
  display: block;
}
.order-button {
  /* background-color: #0d1f49; */
}
.order-button:hover {
  opacity: 0.8;
}
